/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, FormControl, Grid, Heading, HFlow, Text, Theme, Tooltip, useTheme } from 'bold-ui'
import { useAlert } from 'components/alert'
import {
  CheckboxField,
  DateField,
  ErrorField,
  Form,
  FormRenderProps,
  HorarioAgendaSelectField,
  LotacaoAgendaSelectField,
  TextAreaField,
} from 'components/form'
import { addMonths, formatISO, parseISO } from 'date-fns'
import { FORM_ERROR } from 'final-form'
import {
  useAtualizarContatoCidadaoMutation,
  useSalvarAgendamentoConsultaCompartilhadaMutation,
} from 'graphql/hooks.generated'
import { Fragment, useMemo, useState } from 'react'
import { metaPath } from 'util/metaPath'
import { formatNomeCidadao } from 'view/cidadao/util-cidadao'
import { useVideochamadasConfiguration } from 'view/videochamada/hooks/useVideochamadasConfiguration'

import { downloadImpressaoAgendamentoCidadao } from '../components/impressao/downloadImpressaoAgendamentos'
import { InserirVideochamadaAgendamentoSection } from '../components/InserirVideochamadaAgendamentoSection'
import {
  convertConsultaCompartilhadaModelToInput,
  convertConsultaModelToUpdateContatoCidadaoInput,
} from '../converter-agenda'
import { useVerificarAgendamentosConflitantes } from '../hooks/useVerificarAgendamentosConflitantes'
import { AgendamentoConsultaCompartilhadaModel, LotacaoAgendaModel } from '../model-agenda'
import { buildAgendamentosConflitantesErrorMessage } from '../util-agenda'
import { agendamentoConsultaCompartilhadaDecorator } from './calculator-agenda'
import { CidadaoAgendamentoCompartilhadoAccordion } from './CidadaoAgendamentoCompartilhadoAccordion'
import { agendamentoConsultaCompartilhadaValidator } from './validator-agenda'

export const meta = metaPath<AgendamentoConsultaCompartilhadaModel>()

export interface AgendamentoConsultaCompartilhadaFormProps {
  lotacao: LotacaoAgendaModel
  onSubmit?(): void
  onClose(): void
  getServerTimeNow(): Date
  start?: number
  end?: number
}

export const AgendamentoConsultaCompartilhadaForm = (props: AgendamentoConsultaCompartilhadaFormProps) => {
  const { lotacao, onSubmit, onClose, getServerTimeNow, start, end } = props

  const serverTime = getServerTimeNow()
  const alert = useAlert()

  const { loading: loadingConfiguracoesVideochamada, videochamadasEnabled } = useVideochamadasConfiguration()
  const [videochamadaUuid, setVideochamadaUuid] = useState<string>()

  const [salvar] = useSalvarAgendamentoConsultaCompartilhadaMutation()
  const [atualizarContatoCidadao] = useAtualizarContatoCidadaoMutation()
  const theme = useTheme()
  const classes = createStyles(theme)

  const updateContato = (values: AgendamentoConsultaCompartilhadaModel) => {
    const contatoCidadao = values.cidadaoParticipante?.cidadao?.contato
    const contatoAtualizado = values.cidadaoParticipante?.contato
    const telefoneCelularAlterado = contatoCidadao?.telefoneCelular !== contatoAtualizado?.telefoneCelular
    const emailAlterado = contatoCidadao?.email !== contatoAtualizado?.email

    if (telefoneCelularAlterado || emailAlterado)
      return atualizarContatoCidadao({
        variables: {
          input: convertConsultaModelToUpdateContatoCidadaoInput(
            values.cidadaoParticipante.cidadao.id,
            contatoAtualizado
          ),
        },
      })
  }

  const processaImpressaoAgendamento = (values: AgendamentoConsultaCompartilhadaModel, agendamentoId: string) => {
    if (values.imprimirAgendamento && !!values.cidadaoParticipante?.cidadao) {
      const nomeCidadao = formatNomeCidadao(values.cidadaoParticipante.cidadao)
      downloadImpressaoAgendamentoCidadao({
        agendamentoId,
        nomeCidadao,
      })
    }
  }

  const save = (values: AgendamentoConsultaCompartilhadaModel) =>
    salvar({
      variables: { input: convertConsultaCompartilhadaModelToInput(values, lotacao.id, videochamadaUuid) },
    }).then(({ data: { salvarAgendamentoConsultaCompartilhada: agendamentoId } }) => {
      alert('success', 'Agendamento entre profissionais cadastrado com sucesso.')
      processaImpressaoAgendamento(values, agendamentoId)
      onSubmit?.()
      onClose()
    })

  const { verificarAgendamentosConflitantes } = useVerificarAgendamentosConflitantes()

  const handleFormSubmit = async (values: AgendamentoConsultaCompartilhadaModel) => {
    const identificadorAgendamentoConflitanteInput = [
      { lotacaoId: lotacao.id, profissionalId: lotacao.profissional.id, horario: Number(values.horario.inicial) },
    ]
    try {
      if (!!values.cidadaoParticipante?.cidadao?.id) {
        const confirmed = await verificarAgendamentosConflitantes(
          values.cidadaoParticipante.cidadao.id,
          identificadorAgendamentoConflitanteInput,
          values.cidadaoParticipante.cidadao.nomeSocial ?? values.cidadaoParticipante.cidadao.nome
        )

        if (confirmed) await updateContato(values)
        else return
      }

      return await save(values)
    } catch (apolloError) {
      // TODO@RNG #17670 Quando eh clicado em qualquer parte da tela o popper eh fechado e portanto nao temos mais o form, verificar se iremos trabalhar para conseguir manter o mesmo aberto para poder apresentar os erros em cada field
      const error = buildAgendamentosConflitantesErrorMessage(apolloError)
      alert('danger', error)
    }
  }

  const initialValues = useMemo(
    () => ({
      horario: { inicial: new Date(start), final: new Date(end) },
      data: start ? formatISO(start) : undefined,
    }),
    [end, start]
  )

  const renderForm = (formProps: FormRenderProps<Partial<AgendamentoConsultaCompartilhadaModel>>) => {
    const { handleSubmit, values } = formProps
    const desabilitarImpressaoConsulta = !values.cidadaoParticipante?.cidadao

    return (
      <Fragment>
        <div css={styles.box}>
          <Grid>
            {/*TODO (RNG): Encontrar maneira de nao renderizar esta cell quando nao possui erro (#20422)*/}
            <Cell size={12}>
              <ErrorField name={FORM_ERROR} inline={false} type='alert' ignoreTouchedAndDirty />
            </Cell>
            <Cell size={3}>
              <DateField
                name={meta.data}
                label='Data'
                minDate={serverTime}
                maxDate={addMonths(serverTime, 12)}
                clearable
                required
              />
            </Cell>

            <Cell size={3}>
              <HorarioAgendaSelectField
                name={meta.horario}
                lotacaoId={lotacao.id}
                outraLotacaoIdVerificarDisponibilidade={values.lotacaoConvidada?.id}
                dia={values.data ? parseISO(values.data) : undefined}
                disabled={!values.data}
                labels={{ inicial: 'Horário' }}
                clearable
                required
              />
            </Cell>

            <Cell size={6}>
              {(videochamadasEnabled || loadingConfiguracoesVideochamada) && (
                <InserirVideochamadaAgendamentoSection
                  videochamadaUuid={videochamadaUuid}
                  onVideochamadaUuidChange={setVideochamadaUuid}
                  onRemoverVideochamadaClick={() => setVideochamadaUuid(null)}
                />
              )}
            </Cell>

            <Cell size={12}>
              <Heading level={4}>Dados do profissional convidado</Heading>
            </Cell>
            <Cell size={6}>
              <LotacaoAgendaSelectField
                name={meta.lotacaoConvidada}
                label='Profissional'
                excludeProfissionaisIds={[lotacao.profissional.id]}
                includeEquipesComVinculo
                somenteCadastrarAgendamento
                required
              />
            </Cell>
            <Cell size={6} />

            <Cell
              size={6}
              style={css`
                padding-bottom: 0;
              `}
            >
              <FormControl label='E-mail'>
                <Text>{values.lotacaoConvidada?.profissional.email ?? '-'}</Text>
              </FormControl>
            </Cell>
            <Cell
              size={6}
              style={css`
                padding-bottom: 0;
              `}
            />
            <Cell
              size={12}
              style={css`
                padding-top: 0;
                padding-bottom: 0;
                margin-top: 0;
              `}
            >
              <CidadaoAgendamentoCompartilhadoAccordion
                title='Adicionar cidadão'
                id='adicionarCidadaoAgendamentoCompartilhadoAccordion'
                accordionName={meta.openedAccordions}
                name={meta.cidadaoParticipante}
                cidadao={values.cidadaoParticipante?.cidadao}
              />
            </Cell>
            <Cell
              size={12}
              style={css`
                padding-top: 0;
                padding-bottom: 0;
              `}
            >
              <TextAreaField label='Observações' name={meta.observacoes} maxLength={200} resize='none' />
            </Cell>
            <Cell size={4} />
          </Grid>
        </div>
        <HFlow justifyContent='flex-end'>
          <Tooltip
            text={
              desabilitarImpressaoConsulta &&
              'Comprovante de agendamento só deve ser impresso para consultas onde o cidadão participa'
            }
          >
            <div>
              <CheckboxField
                name={meta.imprimirAgendamento}
                label='Imprimir comprovante ao salvar'
                disabled={desabilitarImpressaoConsulta}
                style={classes.print}
              />
            </div>
          </Tooltip>

          <Button kind='normal' onClick={onClose} size='medium'>
            Cancelar
          </Button>
          <Button kind='primary' onClick={handleSubmit} size='medium'>
            Salvar
          </Button>
        </HFlow>
      </Fragment>
    )
  }

  return (
    <Form<Partial<AgendamentoConsultaCompartilhadaModel>>
      initialValues={initialValues}
      render={renderForm}
      onSubmit={handleFormSubmit}
      suppressNotificationError //Previne mostrar alerta de validação fora do popup
      validate={agendamentoConsultaCompartilhadaValidator(getServerTimeNow())}
      decorators={agendamentoConsultaCompartilhadaDecorator}
    />
  )
}

const styles = {
  box: css`
    height: 27.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    ::-webkit-scrollbar {
      width: 0.3rem;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-track {
      background-color: #f2f2f2;
    }
  `,
}

const createStyles = (theme: Theme) => ({
  print: css`
    border: 1px solid ${theme.pallete.gray.c80};
    border-radius: 2px;
    padding: 0.75rem 1rem;
  `,
})
