import { EventoAgendaLotacao } from 'view/agenda/model-agenda'

export enum AcaoAgendamentoEnum {
  VISUALIZAR_PRONTUARIO = 'VISUALIZAR_PRONTUARIO',
  INSERIR_LISTA_ATENDIMENTO = 'INSERIR_LISTA_ATENDIMENTO',
  REMOVER_LISTA_ATENDIMENTO = 'REMOVER_LISTA_ATENDIMENTO',
  INFORMAR_FALTA_CIDADAO = 'INFORMAR_FALTA_CIDADAO',
  REMOVER_FALTA_CIDADAO = 'REMOVER_FALTA_CIDADAO',
  CANCELAR_AGENDAMENTO = 'CANCELAR_AGENDAMENTO',
  IMPRIMIR_AGENDAMENTO = 'IMPRIMIR_AGENDAMENTO',
}

export interface AcoesAgendamentoProps {
  event: EventoAgendaLotacao
  eventoElement?: Element
}

export interface AcaoAgendamentoButtonProps extends AcoesAgendamentoProps {
  disabled?: boolean
  tooltip: string
  isDropdown?: boolean
}

export interface StatusAcaoAgendamento {
  visible: boolean
  disabled?: boolean
  tooltip?: string
}
