import { HFlow, Icon } from 'bold-ui'
import { CboSelectField, Form, FormDebouncedValueSpy, ProfissionalSelectModel } from 'components/form'
import { CheckboxGroupField } from 'components/form/field/CheckboxGroupField'
import { PopperButton, PopperControls } from 'components/popper'
import { FormState } from 'final-form'
import { useFilterCounter } from 'hooks/useFilterCounter'
import React from 'react'
import { metaPath } from 'util/metaPath'

import { TipoCompartilhamentoCuidadoPlanoEnum } from '../../cuidado-compartilhado/model-cuidadocompartilhado'
import {
  CompartilhamentoCuidadoTableFilterModel,
  CompartilhamentoCuidadoTableFilterPopperModel,
} from '../model-compartilhamentocuidado'
import { CompartilhamentoCuidadoTableFilterPopper } from './CompartilhamentoCuidadoTableFilterPopper'

interface CompartilhamentoCuidadoTableFilterProps {
  filter: CompartilhamentoCuidadoTableFilterModel
  onFilterChange: (filter: CompartilhamentoCuidadoTableFilterModel) => void
  profissionaisExecutantes: ProfissionalSelectModel[]
  profissionaisSolicitantes: ProfissionalSelectModel[]
}

const path = metaPath<CompartilhamentoCuidadoTableFilterModel>()

export const CompartilhamentoCuidadoTableFilter = (props: CompartilhamentoCuidadoTableFilterProps) => {
  const { filter, onFilterChange, profissionaisExecutantes, profissionaisSolicitantes } = props

  const handlePopperSubmit = (values: CompartilhamentoCuidadoTableFilterPopperModel) => {
    onFilterChange({ ...filter, ...values })
  }
  const renderPopper = (controls: PopperControls) => (
    <CompartilhamentoCuidadoTableFilterPopper
      onSubmit={handlePopperSubmit}
      onClose={controls.close}
      initialValues={filter}
      profissionaisExecutantes={profissionaisExecutantes}
      profissionaisSolicitantes={profissionaisSolicitantes}
    />
  )

  const { tiposCompartilhamento, ...filtersInPopper } = filter
  const countFiltrosAplicados = useFilterCounter(filtersInPopper)

  return (
    <HFlow justifyContent='space-between'>
      <Form
        initialValues={filter}
        render={() => (
          <HFlow alignItems='center'>
            <FormDebouncedValueSpy
              onChange={(formState: FormState<CompartilhamentoCuidadoTableFilterModel>) =>
                onFilterChange(formState.values)
              }
            />
            <CheckboxGroupField
              name={path.tiposCompartilhamento}
              options={[
                {
                  label: 'Cuidado compartilhado',
                  value: TipoCompartilhamentoCuidadoPlanoEnum.CUIDADO_COMPARTILHADO,
                },
                {
                  label: 'Guia de encaminhamento',
                  value: TipoCompartilhamentoCuidadoPlanoEnum.GUIA_ENCAMINHAMENTO,
                },
              ]}
            />
            <CboSelectField name={path.cbo} placeholder='CBO' mostrarIndisponivelLotacao={false} />
          </HFlow>
        )}
      />
      <PopperButton kind='primary' size='small' renderPopper={renderPopper} placement='bottom-end'>
        <Icon icon='filterOutline' />
        {'Filtros' + (countFiltrosAplicados > 0 ? ` (${countFiltrosAplicados})` : '')}
      </PopperButton>
    </HFlow>
  )
}
