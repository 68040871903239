import { Heading, VFlow } from 'bold-ui'
import React from 'react'

import { AgendamentoBox } from '../components/AgendamentoBox'
import { LotacaoCadastroAgendamentoText } from '../components/LotacaoCadastroAgendamentoText'
import { AgendamentoEdicaoModel, TipoAgendamento } from '../model-agenda'
import { AgendamentoReservaForm } from './AgendamentoReservaForm'

export interface AgendamentoEdicaoViewProps {
  agendamento: AgendamentoEdicaoModel
  isAtencaoDomiciliar?: boolean
  onClose?(): void
}

export const AgendamentoEdicaoView = (props: AgendamentoEdicaoViewProps) => {
  const { agendamento, onClose, isAtencaoDomiciliar = false } = props

  const lotacao = agendamento.lotacao

  return (
    <AgendamentoBox>
      <VFlow>
        <Heading level={1}>Editar reserva</Heading>
        <LotacaoCadastroAgendamentoText lotacao={lotacao} />
        {agendamento.tipo === TipoAgendamento.RESERVA && (
          <AgendamentoReservaForm
            agendamento={agendamento}
            isAtencaoDomiciliar={isAtencaoDomiciliar}
            onClose={onClose}
          />
        )}
      </VFlow>
    </AgendamentoBox>
  )
}
