import { DropdownItem, Icon, Tooltip } from 'bold-ui'
import { PopperButton } from 'components/popper'
import { useEditarAgendadoQuery } from 'graphql/hooks.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import React, { PropsWithChildren, useCallback, useMemo } from 'react'
import { useAgendaAuthorization } from 'view/agenda/authorization/useAgendaAuthorization'
import { AgendamentoEdicaoView } from 'view/agenda/cadastro-agendamento/AgendamentoEdicaoView'
import { convertToAgendamentoEdicaoModel } from 'view/agenda/converter-agenda'
import { EventoAgendaLotacao } from 'view/agenda/model-agenda'

import { AcaoAgendamentoButtonProps } from '../model-acoesAgendamento'

interface EditarAgendamentoButtonProps extends Omit<AcaoAgendamentoButtonProps, 'event'> {
  event: Pick<EventoAgendaLotacao, 'id' | 'tipo' | 'isAtencaoDomiciliar' | 'lotacao'>
  eventoElement?: Element
}

export function EditarAgendamentoButton(props: EditarAgendamentoButtonProps) {
  const { event, disabled, tooltip, isDropdown = false } = props

  const { hasAuthorizationAgendar } = useAgendaAuthorization(event.isAtencaoDomiciliar)

  return (
    hasAuthorizationAgendar(event.lotacao) && (
      <Tooltip text={tooltip}>
        {isDropdown ? (
          <DropdownItem disabled={disabled}>
            <EditarAgendamentoPopperButton {...props}>Editar</EditarAgendamentoPopperButton>
          </DropdownItem>
        ) : (
          <EditarAgendamentoPopperButton {...props}>
            <Icon icon='penOutline' />
          </EditarAgendamentoPopperButton>
        )}
      </Tooltip>
    )
  )
}

const EditarAgendamentoPopperButton = ({
  event,
  eventoElement,
  children,
  ...rest
}: PropsWithChildren<EditarAgendamentoButtonProps>) => {
  const {
    data: { agendado },
  } = useEditarAgendadoQuery({
    variables: { id: event.id },
  })

  const { analytics } = useFirebase()

  const handlePopperOpen = useCallback(() => {
    analytics.logEvent('edicao_agendamento_reserva')
  }, [analytics])

  const edicaoModel = useMemo(() => convertToAgendamentoEdicaoModel(agendado, event.tipo), [agendado, event.tipo])

  return (
    <PopperButton
      placement='bottom-start'
      positioningStrategy='absolute'
      type='button'
      skin='ghost'
      size='small'
      z-index={1}
      onOpen={handlePopperOpen}
      referenceElement={eventoElement}
      renderPopper={(controls) => (
        <AgendamentoEdicaoView
          onClose={controls.close}
          agendamento={edicaoModel}
          isAtencaoDomiciliar={event.isAtencaoDomiciliar}
        />
      )}
      {...rest}
    >
      {children}
    </PopperButton>
  )
}
