import { Cnes } from 'api/Cnes'
import { FichaSinan } from 'api/FichaSinan'
import { Transmissao } from 'api/Transmissao'

import { AcompanhamentoCidadaosVinculados } from './AcompanhamentoCidadaosVinculados'
import { AcompanhamentoSaudeRelatorio } from './AcompanhamentoSaudeRelatorio'
import { Arquivo } from './Arquivo'
import { AtencaoDomiciliar } from './AtencaoDomiciliar'
import { Atestado } from './Atestado'
import { BuscaAtivaVacinacao } from './BuscaAtivaVacinacao'
import { ExportarCadastrosDuplicados } from './ExportarCadastrosDuplicados'
import { ImportarCidadao } from './ImportarCidadao'
import { ImpressaoAcompanhamentoPreNatal } from './ImpressaoAcompanhamentoPreNatal'
import { ImpressaoAcompanhamentoPuericultura } from './impressaoAcompanhamentoPuericultura'
import { ImpressaoAgendamento } from './ImpressaoAgendamento'
import { ImpressaoAtendimentoIndividual } from './ImpressaoAtendimentoIndividual'
import { ImpressaoAtendimentoVacinacao } from './ImpressaoAtendimentoVacinacao'
import { ImpressaoCadernetaVacinacao } from './impressaoCadernetaVacinacao'
import { ImpressaoEncaminhamentoExterno } from './ImpressaoEncaminhamentoExterno'
import { ImpressaoGuiaEncaminhamentoCuidado } from './ImpressaoGuiaEncaminhamentoCuidado'
import { ImpressaoMedicamentos } from './ImpressaoMedicamentos'
import { ImpressaoRegistrosHistorico } from './ImpressaoRegistrosHistorico'
import { ImpressaoSolicitacaoExames } from './ImpressaoSolicitacaoExames'
import { PrescricaoDigital } from './PrescricaoDigital'

export default {
  cnes: new Cnes(),
  transmissao: new Transmissao(),
  fichaSinan: new FichaSinan(),
  acompanhamentoSaude: new AcompanhamentoSaudeRelatorio(),
  acompanhamentoPreNatal: new ImpressaoAcompanhamentoPreNatal(),
  atencaoDomiciliar: new AtencaoDomiciliar(),
  atendimentoIndividual: new ImpressaoAtendimentoIndividual(),
  registrosHistorico: new ImpressaoRegistrosHistorico(),
  prescricaoMedicamento: new ImpressaoMedicamentos(),
  prescricaoDigital: new PrescricaoDigital(),
  atendimentoVacinacao: new ImpressaoAtendimentoVacinacao(),
  cadernetaVacinacao: new ImpressaoCadernetaVacinacao(),
  encaminhamentoExterno: new ImpressaoEncaminhamentoExterno(),
  guiaEncaminhamentoCuidado: new ImpressaoGuiaEncaminhamentoCuidado(),
  solicitacaoExames: new ImpressaoSolicitacaoExames(),
  agendamento: new ImpressaoAgendamento(),
  acompanhamentoPuericultura: new ImpressaoAcompanhamentoPuericultura(),
  acompanhamentoCidadaosVinculados: new AcompanhamentoCidadaosVinculados(),
  buscaAtivaVacinacao: new BuscaAtivaVacinacao(),
  arquivo: new Arquivo(),
  exportarCadastrosDuplicados: new ExportarCadastrosDuplicados(),
  importarCidadao: new ImportarCidadao(),
  atestado: new Atestado(),
}
