import { format, isAfter, isBefore, isSameDay } from 'date-fns'
import { SituacaoAgendadoEnum, StatusAtendimento } from 'graphql/types.generated'

import { AcaoAgendamentoEnum, StatusAcaoAgendamento } from './model-acoesAgendamento'

export function getStatusAcoesConsulta({
  situacao,
  situacaoAtendimento,
  possuiAtendimentoPrevio = false,
  isForaUbs,
  dataAtual,
  dataConsulta,
}: {
  situacao: SituacaoAgendadoEnum
  situacaoAtendimento?: StatusAtendimento
  possuiAtendimentoPrevio?: boolean
  isForaUbs: boolean
  dataAtual: Date
  dataConsulta: Date
}): Record<AcaoAgendamentoEnum, StatusAcaoAgendamento> {
  const isDiaPassado = isBefore(dataConsulta, dataAtual)
  const isDiaFuturo = isAfter(dataConsulta, dataAtual)
  const isMesmoDia = isSameDay(dataConsulta, dataAtual)
  const isHorarioAtualOuFuturoMesmoDia = isMesmoDia && !isDiaPassado
  const horaFormatada = format(dataConsulta, 'HH:mm')
  const horaDataFormatada = format(dataConsulta, "dd/MM/yyyy 'após' HH:mm")
  const isAgendado = [SituacaoAgendadoEnum.AGENDADO].includes(situacao)

  return {
    VISUALIZAR_PRONTUARIO: { visible: true, disabled: false, tooltip: 'Visualizar Prontuário' },

    INSERIR_LISTA_ATENDIMENTO: {
      visible:
        !isForaUbs &&
        isMesmoDia &&
        [
          SituacaoAgendadoEnum.AGENDADO,
          SituacaoAgendadoEnum.NAO_AGUARDOU,
          SituacaoAgendadoEnum.NAO_COMPARECEU,
        ].includes(situacao),

      disabled: [SituacaoAgendadoEnum.NAO_COMPARECEU, SituacaoAgendadoEnum.NAO_AGUARDOU].includes(situacao),

      tooltip:
        SituacaoAgendadoEnum.NAO_COMPARECEU === situacao
          ? 'Não é possível adicionar à lista de atendimento um cidadão que não compareceu'
          : SituacaoAgendadoEnum.NAO_AGUARDOU === situacao
          ? 'Não é possível adicionar à lista de atendimento um cidadão que não aguardou'
          : 'Inserir na lista de atendimento',
    },

    REMOVER_LISTA_ATENDIMENTO: {
      visible:
        isHorarioAtualOuFuturoMesmoDia &&
        [SituacaoAgendadoEnum.CIDADAO_PRESENTE_NA_UNIDADE, SituacaoAgendadoEnum.ATENDIMENTO_REALIZADO].includes(
          situacao
        ),

      disabled:
        [SituacaoAgendadoEnum.ATENDIMENTO_REALIZADO].includes(situacao) ||
        (situacaoAtendimento &&
          ([StatusAtendimento.EM_ESCUTA_INICIAL, StatusAtendimento.EM_ATENDIMENTO].includes(situacaoAtendimento) ||
            (situacaoAtendimento === StatusAtendimento.AGUARDANDO_ATENDIMENTO && possuiAtendimentoPrevio))),

      tooltip: [SituacaoAgendadoEnum.ATENDIMENTO_REALIZADO].includes(situacao)
        ? 'Não é possível remover o cidadão da lista pois o atendimento foi realizado'
        : situacaoAtendimento &&
          ([StatusAtendimento.EM_ESCUTA_INICIAL, StatusAtendimento.EM_ATENDIMENTO].includes(situacaoAtendimento) ||
            (situacaoAtendimento === StatusAtendimento.AGUARDANDO_ATENDIMENTO && possuiAtendimentoPrevio))
        ? 'Não é possível remover o cidadão da lista pois ele já foi atendido'
        : 'Remover cidadão da lista de atendimentos',
    },

    INFORMAR_FALTA_CIDADAO: {
      visible: !isForaUbs && ![SituacaoAgendadoEnum.NAO_COMPARECEU].includes(situacao),

      disabled:
        isDiaFuturo ||
        [
          SituacaoAgendadoEnum.CIDADAO_PRESENTE_NA_UNIDADE,
          SituacaoAgendadoEnum.NAO_AGUARDOU,
          SituacaoAgendadoEnum.ATENDIMENTO_REALIZADO,
        ].includes(situacao),

      tooltip:
        isHorarioAtualOuFuturoMesmoDia && isAgendado
          ? `Só será possível informar falta hoje após ${horaFormatada}`
          : isDiaFuturo && isAgendado
          ? `Só será possível informar falta em ${horaDataFormatada}`
          : getTooltipInformarFalta(situacao),
    },

    REMOVER_FALTA_CIDADAO: {
      visible: !isForaUbs && (isMesmoDia || isDiaPassado) && [SituacaoAgendadoEnum.NAO_COMPARECEU].includes(situacao),

      disabled: !isMesmoDia,

      tooltip: isMesmoDia ? 'Cancelar falta do cidadão' : 'Não é possível remover falta de agendamentos passados.',
    },

    CANCELAR_AGENDAMENTO: {
      visible: true,

      disabled:
        isDiaPassado ||
        (isMesmoDia &&
          ([
            SituacaoAgendadoEnum.CIDADAO_PRESENTE_NA_UNIDADE,
            SituacaoAgendadoEnum.NAO_AGUARDOU,
            SituacaoAgendadoEnum.ATENDIMENTO_REALIZADO,
            SituacaoAgendadoEnum.NAO_COMPARECEU,
          ].includes(situacao) ||
            (isForaUbs && situacaoAtendimento !== StatusAtendimento.AGUARDANDO_ATENDIMENTO))),

      tooltip: isDiaPassado
        ? 'Não é possível cancelar agendamentos passados'
        : isForaUbs && situacaoAtendimento !== StatusAtendimento.AGUARDANDO_ATENDIMENTO
        ? 'Não é possível cancelar pois o atendimento já foi registrado'
        : getTooltipCancelar(situacao),
    },

    IMPRIMIR_AGENDAMENTO: {
      visible: true,

      disabled:
        isDiaPassado ||
        (isMesmoDia &&
          [
            SituacaoAgendadoEnum.CIDADAO_PRESENTE_NA_UNIDADE,
            SituacaoAgendadoEnum.NAO_AGUARDOU,
            SituacaoAgendadoEnum.ATENDIMENTO_REALIZADO,
            SituacaoAgendadoEnum.NAO_COMPARECEU,
          ].includes(situacao)),

      tooltip: isDiaPassado
        ? 'Não é possível imprimir comprovante de agendamentos passados'
        : getTooltipImprimir(situacao),
    },
  }
}

const getTooltipCancelar = (situacao: SituacaoAgendadoEnum) => {
  switch (situacao) {
    case SituacaoAgendadoEnum.NAO_AGUARDOU:
      return 'Não é possível cancelar agendamento de um cidadão que não aguardou'
    case SituacaoAgendadoEnum.CIDADAO_PRESENTE_NA_UNIDADE:
      return 'Não é possível cancelar agendamento de um cidadão presente na UBS'
    case SituacaoAgendadoEnum.NAO_COMPARECEU:
      return 'Não é possível cancelar agendamento de um cidadão que não compareceu'
    case SituacaoAgendadoEnum.ATENDIMENTO_REALIZADO:
      return 'Não é possível cancelar agendamento de um atendimento realizado'
    default:
      return 'Cancelar'
  }
}

const getTooltipImprimir = (situacao: SituacaoAgendadoEnum) => {
  switch (situacao) {
    case SituacaoAgendadoEnum.NAO_AGUARDOU:
      return 'Não é possível imprimir comprovante de agendamentos para um cidadão que não aguardou'
    case SituacaoAgendadoEnum.CIDADAO_PRESENTE_NA_UNIDADE:
      return 'Não é possível imprimir comprovante de agendamentos de um cidadão presente na UBS'
    case SituacaoAgendadoEnum.NAO_COMPARECEU:
      return 'Não é possível imprimir comprovante de agendamentos de um cidadão que não compareceu'
    case SituacaoAgendadoEnum.ATENDIMENTO_REALIZADO:
      return 'Não é possível imprimir comprovante de agendamentos de um atendimento realizado'
    default:
      return 'Imprimir agendamento'
  }
}

const getTooltipInformarFalta = (situacao: SituacaoAgendadoEnum) => {
  switch (situacao) {
    case SituacaoAgendadoEnum.CIDADAO_PRESENTE_NA_UNIDADE:
      return 'Não é possível informar falta de um cidadão presente na UBS'
    case SituacaoAgendadoEnum.NAO_AGUARDOU:
      return 'Não é possível informar falta de um cidadão que não aguardou'
    case SituacaoAgendadoEnum.ATENDIMENTO_REALIZADO:
      return 'Não é possível informar falta do cidadão pois o atendimento foi realizado'
    default:
      return 'Informar falta do cidadão'
  }
}
