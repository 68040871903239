import { Button, DropdownItem, HFlow, Icon, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DropdownButton } from 'components/dropdown'
import { confirm } from 'components/modals/confirm'
import React from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { VisualizarDiscussaoCuidadoCompartilhadoAction } from 'view/atendimentos/detail/historico/components/accordion/header/components/VisualizarDiscussaoCuidadoCompartilhadoAction'

import { CompartilhamentoCuidadoPlanoModel } from '../model-compartilhamentocuidado'

interface CompartilhamentoCuidadoTableActionsProps {
  row: CompartilhamentoCuidadoPlanoModel
  viaFolhaRosto: boolean
  onEdit?: (id: ID) => void
  onDelete?: (id: ID) => void
  onImprimir?: (row: CompartilhamentoCuidadoPlanoModel) => void
}

export const CompartilhamentoCuidadoTableActions = (props: CompartilhamentoCuidadoTableActionsProps) => {
  const { row, viaFolhaRosto, onEdit, onDelete, onImprimir } = props

  const alert = useAlert()

  const handleDelete = (id: ID) =>
    confirm({
      title: `Deseja excluir o ${row.isEncaminhamento ? 'encaminhamento' : 'cuidado compartilhado'}?`,
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () => {
        onDelete(id)
        alert('success', `${row.isEncaminhamento ? 'Encaminhamento' : 'Cuidado compartilhado'} excluído com sucesso`)
      },
    })()

  const onClickEdit = (e: React.MouseEvent) => {
    e.stopPropagation()
    return onEdit(row.cacheId)
  }

  const onClickDelete = (e: React.MouseEvent) => {
    e.stopPropagation()
    return handleDelete(row.cacheId)
  }

  const onClickImprimir = (e: React.MouseEvent) => {
    e.stopPropagation()
    return onImprimir(row)
  }

  return row.isRegistradoAgora
    ? (onEdit || onDelete) && (
        <HFlow alignItems='center' justifyContent='flex-end' hSpacing={0.5}>
          {row.isEncaminhamento && (
            <Tooltip text='Imprimir'>
              <Button size='small' skin='ghost' onClick={onClickImprimir}>
                <Icon icon='printerOutline' />
              </Button>
            </Tooltip>
          )}
          <Tooltip text='Mais opções'>
            <DropdownButton stopPropagationOnClick>
              {onEdit && (
                <DropdownItem type='normal' onClick={onClickEdit}>
                  Editar
                </DropdownItem>
              )}

              {onDelete && (
                <DropdownItem type='danger' onClick={onClickDelete}>
                  <HFlow alignItems='center' hSpacing={0.5}>
                    <Icon icon='trashOutline' />
                    Excluir
                  </HFlow>
                </DropdownItem>
              )}
            </DropdownButton>
          </Tooltip>
        </HFlow>
      )
    : !isUndefinedOrNull(row.dbId) && (
        <HFlow alignItems='center' justifyContent='flex-end' hSpacing={0.5}>
          {!row.isEncaminhamento ? (
            <VisualizarDiscussaoCuidadoCompartilhadoAction
              cuidadoCompartilhadoId={row.dbId}
              viaFolhaRosto={viaFolhaRosto}
            />
          ) : (
            <Tooltip text='Imprimir'>
              <Button size='small' skin='ghost' onClick={onClickImprimir}>
                <Icon icon='printerOutline' />
              </Button>
            </Tooltip>
          )}
        </HFlow>
      )
}
